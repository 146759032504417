
export default {
  props: ["elements"],
  data() {
    return {
      newsletterAgree: false,
      newsLetterEmail: "",
      categoryOpen: false,
      items: {
        offers: [],
        latest: [],
        actual: [],
      },
      pagination: [],
      total: 0,
      total: 0,
      limit: 24,
      page: 1,
      pages: 1,
      search: "",
      articles: [],
      top10: [],
      bestSellers: [],
      fresh: [],
      offers: [],
    };
  },
  beforeCreate() {},
  async beforeMount() {},
  async mounted() {
    // this.elements = this.$store.getters.store.eshop.elements.filter(
    //   (e) => e.page == this.$store.getters.store.eshop.layout.index
    // );
    // this.getArticles();
  },
  methods: {
    async init() {},
    getArticles() {
      this.articles = [];
      this.$axios
        .$get(`blog?&limit=3&store_id=${this.$store.getters.store.store_id}`)
        .then((res) => {
          if (res.docs) {
            this.articles = res.docs.map((a) => {
              a.short = a.html;
              return a;
            });
          }
        });
    },
    async doSearch(search, ignore, pool) {
      if (search.includes("is_offer")) {
        this.offers = true;
        this.fa = "";
        this.ba = "";
      } else {
        this.offers = false;
      }
      this.loading = true;
      search += `&limit=16`;
      this.nothingFound = false;
      let temp = await this.$hyperItem.getItems(`${search}`);
      this.loading = false;
      temp.items.docs = temp.items.docs.map((i) => {
        if (!i.quantity) {
          i.quantity = 1;
        }
        return i;
      });
      if (pool) {
        this.items[pool] = temp.items.docs;
        return;
      }
      if (!ignore) {
        this.items.actual = temp.items.docs;
      } else {
        this.items.actual = [...this.items.actual, ...temp.items.docs];
      }

      this.page = temp.items.page;
      this.pages = temp.items.pages;
      this.limit = temp.items.limit;
      this.total = temp.items.total;
      this.filters = temp;
      this.pagination = [];
    },
    getPromos() {
      this.$axios
        .$get(`promos/store/${this.$store.getters.store.store_id}`)
        .then((res) => {
          this.promos = res.docs
            .filter((p) => p.order != -1)
            .map((p) => p.media);
        })
        .catch((e) => {
          this.$clerk.hyperAlert({
            time: 3000,
            title: "Δεν ενημερώθηκαν οι προσφορές",
          });
        });
      return;
    },
  },
};
